<template>
  <div>
    <div class="hero">
      <v-row class="text-center">
        <v-col cols="12" md="3"></v-col>
        <v-col cols="12" md="6" class="mb-5 text-center justify-center">
          <v-img
            src="img/logo_texto.svg"
            lazy-src="img/logo_texto.svg"
            class="ma-auto"
            max-width="450"
          />
          <h3>
            La combinación de excelencia académica con la experiencia ganada
            durante muchos años nos han dado las herramientas suficientes para
            poder brindarle un servicio de alto nivel profesional.
          </h3>
        </v-col>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row class="text-center mt-5">
        <v-col cols="12" md="2"></v-col>
        <v-col cols="12" md="8">
          <v-carousel cycle height="500" :show-arrows="false">
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="'/img/galeria/small/' + item + '.jpg'"
              reverse-transition="fade-transition"
              transition="fade-transition"
              hide-delimiters
            ></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" md="2"></v-col>
      </v-row>

      <v-row>
        <v-col class="ma-0 pa-0">
          <svg
            class="hero-waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28 "
            preserveAspectRatio="none"
          >
            <defs>
              <path
                id="wave-path"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              ></path>
            </defs>
            <g class="wave1">
              <use
                xlink:href="#wave-path"
                x="50"
                y="3"
                fill="rgba(255,255,255, .1)"
              ></use>
            </g>
            <g class="wave2">
              <use
                xlink:href="#wave-path"
                x="50"
                y="0"
                fill="rgba(255,255,255, .2)"
              ></use>
            </g>
            <g class="wave3">
              <use xlink:href="#wave-path" x="50" y="9" fill="#fff"></use>
            </g>
          </svg>
        </v-col>
      </v-row>
    </div>
    <v-container class="infop">
      <v-row>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="10">
          <v-card class="pa-6" color="rgb(0,0,0,0.1)" elevation="0">
            <v-card-title class="text-center justify-center"
              >AREAS DE TRABAJO</v-card-title
            >
            <v-row>
              <v-col cols="12" md="6">
                <transition name="fade" appear>
                  <v-card
                    min-height="270"
                    elevation="9"
                    dark
                    class="v-card-servicio"
                  >
                    <v-card-text>
                      <div class="title mb-6">
                        <h2>Area</h2>
                        <p>IMPOSITIVA</p>
                      </div>
                      En un contexto de gran presión tributaria en el que los
                      impuestos tienen cada vez más influencia en los negocios,
                      es necesario aprender a medir su efecto para tomar las
                      decisiones que resulten más adecuadas para su
                      emprendimiento.
                    </v-card-text>
                  </v-card>
                </transition>
              </v-col>
              <v-col cols="12" md="6">
                <transition name="fade" appear>
                  <v-card
                    min-height="270"
                    elevation="9"
                    dark
                    class="v-card-servicio"
                  >
                    <v-card-text>
                      <div class="title mb-6">
                        <h2>Area</h2>
                        <p>LABORAL Y PREVISIONAL</p>
                      </div>
                      Bien es sabido que en la actualidad los recursos humanos
                      de una firma, sea cual sea su tamaño, son parte
                      indispensable del negocio pero que también constituyen una
                      parte muy importante de sus costos. Por eso, en esta área
                      del estudio les brindamos a nuestros clientes
                      asesoramiento integral (contable y jurídico) con el
                      objetivo de lograr el mayor rendimiento dentro de la
                      legislación vigente.
                    </v-card-text>
                  </v-card>
                </transition>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <transition name="fade" appear>
                  <v-card
                    min-height="270"
                    elevation="9"
                    dark
                    class="v-card-servicio"
                  >
                    <v-card-text>
                      <div class="title mb-6">
                        <h2>Area</h2>
                        <p>CONTABLE Y AUDITORIA</p>
                      </div>
                      En esta área del estudio se brinda asesoramiento respecto
                      a la preparación y confección de los estados contables de
                      una empresa, cuidando el cumplimiento de normas contables
                      vigentes y las políticas internas del negocio del cliente.
                    </v-card-text>
                  </v-card>
                </transition>
              </v-col>
              <v-col cols="12" md="6">
                <transition name="fade" appear>
                  <v-card
                    min-height="270"
                    elevation="9"
                    dark
                    class="v-card-servicio"
                  >
                    <v-card-text>
                      <div class="title mb-6">
                        <h2>Area</h2>
                        <p>ADMINISTRACION</p>
                      </div>
                      Un buen sistema de Información Contable de una
                      Organización suministra la información necesaria para la
                      toma de decisiones. Nuestro estudio contempla el
                      asesoramiento relacionado con una correcto diseño e
                      implementación del Sistema de Información Contable.
                    </v-card-text>
                  </v-card>
                </transition>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: "%s - Home",
  },
  data: () => ({
    items: [5, 10, 22],
  }),
};
</script>
<style>
.title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #fd9042;
  margin: 4px 10px;
}
.title p {
  margin: 0;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}
.hero {
  color: #fff;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);
  padding: 0;
}
.hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

.hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
}

/**** Hero Waver Animate */

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}
.v-card-servicio {
  background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);
}
/* TRANSITION */
.fade-enter-active, .fade-leave-active {
  transition: opacity 4s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform-origin: 50px 50px;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
</style>
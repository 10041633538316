<template>
  <v-container>
    <v-row class="text-justify">
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10" class="mb-5">
        <v-card class="pa-6" color="rgb(0,0,0,0.1)" elevation="0">
          <v-card>
            <v-card-text>
              <v-carousel cycle min-height="800" hide-delimiters >
                <v-carousel-item
                  v-for="(item, i) in items"
                  :key="i"
                  :src="'/img/galeria/small/' + item + '.jpg'"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  hide-delimiters
                ></v-carousel-item>
              </v-carousel>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
    };
  },
};
</script>

<style>
</style>
<template>
  <v-app-bar app align-center justify-center dark color="rgb(48, 50, 65)">
    <v-spacer class="d-none d-sm-flex"></v-spacer>
    <v-toolbar-title>
      <a href="https://contadoreslaplata.com.ar">
        <v-img
          contain
          :src="'/img/logo_cuadrado.svg'"
          max-height="64"
          max-width="64"
          class="ml-4"
        />
      </a>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn text class="d-none d-sm-flex" color="white" to="/">Inicio</v-btn>
      <v-btn text class="d-none d-sm-flex" color="white" to="/quienes_somos">
        Quienes Somos ?
      </v-btn>
      <v-btn text class="d-none d-sm-flex" color="white" to="/galeria">
        Galería de Imágenes
      </v-btn>
      <v-btn text class="d-none d-sm-flex" color="white" to="/contacto">
        Contacto
      </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <div class="d-flex d-sm-none">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/">
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>
          <v-list-item to="/quienes_somos">
            <v-list-item-title>Quienes Somos ?</v-list-item-title>
          </v-list-item>
          <v-list-item to="/galeria">
            <v-list-item-title>Galería de Imágenes</v-list-item-title>
          </v-list-item>
          <v-list-item to="/contacto">
            <v-list-item-title>Contacto</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style scoped>
header {
  background-color: #303241;
}
.v-btn--active {
  background-color: #ef6603;
  color: #fff;
}
</style>
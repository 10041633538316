import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/Home'
import QuienesSomos from '../components/QuienesSomos'
import Galeria from '../components/Galeria'
import Contacto from '../components/Contacto'

Vue.use(Router)
const DEFAULT_TITLE = 'Schwab & Asociados';
const DEFAULT_KEYWORDS = ['estudio contable', 'contadores'];
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Inicio',
            component: Home,
            meta: {
                keywords: []
            }
        },
        {
            path: '/quienes_somos',
            name: 'QuienesSomos',
            component: QuienesSomos,
            meta: {
                title: 'Quienes Somos ?',
                keywords: []
            }
        },
        {
            path: '/galeria',
            name: 'Galeria',
            component: Galeria,
            meta: {
                title: 'Galería de Imágenes',
                keywords: []
            }
        },
        {
            path: '/contacto',
            name: 'Contacto',
            component: Contacto,
            meta: {
                title: 'Contacto',
                keywords: []
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    Vue.nextTick(() => {
        if (to.meta.title) {
            document.title = to.meta.title + ' - ' + DEFAULT_TITLE;
            document.keywords = to.meta.keywords;
        } else {
            document.title = DEFAULT_TITLE;
            document.keywords = DEFAULT_KEYWORDS;
        }
    });
    next();
});

export default router
<template>
  <v-container>
    <v-row class="text-justify">
      <v-col cols="12" md="1"></v-col>
      <v-col class="mb-5" cols="12" md="10">
        <v-card class="pa-6" color="rgb(0,0,0,0.1)" elevation="0">
          <v-card-title class="text-center justify-center"
            >Nuestra Firma</v-card-title
          >
          <v-row class="text-justify">
            <v-col class="mb-5" cols="12" md="8">
              <v-card class="v-card-servicio" dark>
                <v-card-text>
                  <div class="title mb-6">
                    <h2>Historia</h2>
                  </div>
                  <p>
                    Schwab & Asociados es un estudio contable con mas de 20 años
                    de experiencia en el mercado local.
                  </p>

                  <p>
                    Nos encontramos en la Ciudad de La Plata, Pcia. de Buenos
                    Aires.
                  </p>

                  <p>
                    Nuestra trayectoria y excelencia académica, nos permite
                    asesorarlo en forma personalizada en la administración
                    eficiente de su negocio, de manera de cumplir con sus
                    necesidades en forma precisa y oportuna.
                  </p>
                  <p>
                    La misión de nuestro estudio es brindar un servicio de alto
                    nivel profesional, ofreciendo soluciones a nuestros clientes
                    en los temas relacionados a nuestra profesión de forma tal
                    que puedan despreocuparse de los mismos y concentrarse en
                    desarrollar su negocio. Es nuestra manera de contribuir a su
                    crecimiento.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="mb-5" cols="12" md="4">
              <v-card>
                <v-card-text>
                  <v-carousel cycle height="400" hide-delimiters>
                    <v-carousel-item
                      v-for="(item, i) in items"
                      :key="i"
                      :src="'/img/galeria/' + item + '.jpg'"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      hide-delimiters
                    ></v-carousel-item>
                  </v-carousel>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [18, 19, 20, 21, 22],
    };
  },
  metaInfo: {
    titleTemplate: "%s",
  },
};
</script>

<template>
  <v-container>
    <v-card class="pa-6" color="rgb(0,0,0,0.1)" elevation="0">
      <v-card-title class="text-center justify-center">
        Contactenos
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" class="text-center justify-center">
            <v-card class="pa-4" color="rgb(255, 255, 255, 0.3)" elevation="0">
              <v-card>
                <GmapMap
                  :center="{
                    lat: -34.895848273548246,
                    lng: -57.95248148157729,
                  }"
                  :zoom="14"
                  map-type-id="roadmap"
                  style="height: 522px"
                >
                  <GmapMarker
                    ref="myMarker"
                    :position="
                      google &&
                      new google.maps.LatLng(
                        -34.895848273548246,
                        -57.95248148157729
                      )
                    "
                  />
                </GmapMap>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="text-center justify-center">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col>
                  <p>
                    <v-btn
                      link
                      text
                      href="https://www.google.com/maps/place/CCK,+Calle+118+269,+B1902+La+Plata,+Provincia+de+Buenos+Aires/@-34.8956246,-57.9531199,19z/data=!4m5!3m4!1s0x95a2e650dba9410b:0xca7b0764a2a5f177!8m2!3d-34.8957869!4d-57.9526673"
                      target="_blank"
                    >
                      <v-icon color="#ef6603">mdi-map</v-icon>Calle 118 269, La
                      Plata
                    </v-btn>
                  </p>
                  <p>
                    <v-btn
                      link
                      text
                      href="https://wa.me/5492214099087?text=Me gustaria obtener mas información"
                      target="_blank"
                    >
                      <v-icon color="#ef6603">mdi-whatsapp</v-icon>+54 9 221
                      4099087</v-btn
                    >
                  </p>
                  <p>
                    <v-btn link text
                      ><v-icon color="#ef6603">mdi-phone</v-icon>+54 221
                      424-1230</v-btn
                    >
                  </p>
                  <p>
                    <v-btn link text
                      ><v-icon color="#ef6603">mdi-email</v-icon
                      >schwabyasoc@gmail.com</v-btn
                    >
                  </p>
                  <p>
                    <v-btn
                      link
                      text
                      href="https://www.facebook.com/SchwabAsoc-Estudio-Contable-112793993767094"
                      target="_blank"
                      ><v-icon color="#ef6603"> mdi-facebook</v-icon> Facebook
                    </v-btn>
                  </p>
                  <p>
                    <v-btn
                      link
                      text
                      href="https://www.linkedin.com/company/estudio-contable-schwab-asoc/"
                      target="_blank"
                      ><v-icon color="#ef6603">mdi-linkedin</v-icon> LinkedIn
                    </v-btn>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="pa-4" color="rgb(255, 255, 255, 0.3)" elevation="0">
              <v-card v-if="cfSendIt">
                <v-card-text class="text-center justify-center">
                  <h3>Su consulta fue enviada, a la brevedad nos contactaremos.</h3>
                </v-card-text>
              </v-card>
              <v-card v-if="!cfSendIt">
                <v-card-title> Contacto </v-card-title>
                <v-card-text class="mb-0 pb-0">
                  <v-text-field
                    v-model="contactForm.name"
                    :outlined="true"
                    label="Nombre"
                    autocomplete="first-name"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="contactForm.email"
                    :outlined="true"
                    label="Correo Electrónico"
                    autocomplete="email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                  <v-text-field
                    v-model="contactForm.phone"
                    :outlined="true"
                    label="Teléfono"
                    autocomplete="tel"
                  ></v-text-field>
                  <v-textarea
                    v-model="contactForm.text"
                    rows="3"
                    label="Consulta"
                    :outlined="true"
                  />
                </v-card-text>
                <v-card-actions class="mt-0 pt-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="sendContact"
                    :loading="cfSend"
                    :outlined="true"
                    >Enviar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "../firebase";
import { gmapApi } from "vue2-google-maps";

export default {
  data() {
    return {
      cfSend: false,
      cfSendIt: false,
      contactForm: {
        name: "",
        email: "",
        phone: "",
        text: ""       
      },
      rules: {
        required: (value) => !!value || "Requirido",
        counter: (value) =>
          value.length <= 20 || "Debe tener mas de 20 caracteres",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Dirección de Email Inválida";
        },
        fileSize: (value) => {
          return (
            (value && value.size < 20000000) ||
            "The file must be less than 20MB"
          );
        },
      },
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    async sendContact() {
      this.cfSend = true;
      console.log(this.contactForm);
      await db.collection("contact_form").add(this.contactForm);
      this.$emit("contact-form-sent");
      this.$emit("contact-form-close");
      this.cfSend = false;
      this.cfSendIt = true;
    },
  },
};
</script>
<style scoped>
.vue-map-container,
.vue-map-container .vue-map {
  width: 100%;
  height: 100%;
}
</style>
import Vue from 'vue'
import router from "./routes/index";
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAXbh2KJlvqL-3hK7LGKPER0wStzO98xb4',    
  }
});
Vue.config.productionTip = false
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

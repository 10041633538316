import Vue from 'vue'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyAXbh2KJlvqL-3hK7LGKPER0wStzO98xb4",
  authDomain: "contadoreslaplatacomar.firebaseapp.com",
  databaseURL: "https://contadoreslaplatacomar.firebaseio.com",
  projectId: "contadoreslaplatacomar",
  storageBucket: "contadoreslaplatacomar.appspot.com",
  messagingSenderId: "438425195963",
  appId: "1:438425195963:web:0d5a1f441415e1e253209e",
  measurementId: "G-43EZ385VSD"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();
export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const fire = firebase;
// TODO: use functions in 'europe-west1'
<template>
  <v-app>
    <navbar />
    <v-main class="mb-6">
      <v-container fluid class="pa-0 ma-0">
        <router-view></router-view>
      </v-container>
      <v-speed-dial bottom right fixed>
        <v-btn fab dark color="green">
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-main>
    <v-footer inset dark class="mt-6">
      <v-row class="mt-0 mb-0">
        <v-col cols="12" md="2" class="pt-0 pb-0 mt-0 mb-0">&nbsp;</v-col>
        <v-col
          cols="12"
          xs="8"
          md="8"
          class="text-center pt-2 pb-0 mt-0 mb-0"
          style="color: white; font-size: 0.8em"
        >
          <v-btn
            link
            text
            dark
            href="https://wa.me/5492214099087?text=Me gustaria obtener mas información"
            target="_blank"
            small
          >
            <v-icon color="#ef6603">mdi-whatsapp</v-icon>+54 9 221 4099087</v-btn
          >
          <v-btn link text dark small
            ><v-icon color="#ef6603">mdi-phone</v-icon>+54 221 424-1230</v-btn
          >
          <v-btn link text dark small
            ><v-icon color="#ef6603">mdi-email</v-icon
            >schwabyasoc@gmail.com</v-btn
          >
          <v-btn
            link
            text
            dark
            small
            href="https://www.facebook.com/SchwabAsoc-Estudio-Contable-112793993767094"
            target="_blank"
            ><v-icon color="#ef6603"> mdi-facebook</v-icon>
          </v-btn>
          <v-btn
            link
            text
            dark
            small
            href="https://www.linkedin.com/company/estudio-contable-schwab-asoc/"
            target="_blank"
            ><v-icon color="#ef6603">mdi-linkedin</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-right text-xs-center justify-xs-center pt-2 pb-0 mt-0 mb-0"
        >
          <v-btn
            text
            link
            href="https://infovita.net?utm_source=contadoreslaplata.com.ar&utm_campaign=footer"
            target="_blank"
          >
            <img
              src="/img/infovita_foot_white.svg"
              height="20px"
              class="pt-0 pb-0"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import Navbar from "./components/Navbar.vue";
export default {
  metaInfo: {
    title: "Contadores La Plata",
    meta: [
      { name: "description", content: "Contadores" },
      {
        keywords: "monotributo, iva, libros contables, ganancias",
      },
    ],
    htmlAttrs: {
      lang: "es",
      amp: true,
    },
  },
  components: {
    Navbar,
  },
};
</script>
<style>
body,
html {
  font-family: sans-serif;
}
.bg-naranja {
  background-color: #ef6603;
}
.fg-naranja {
  color: #ef6603;
}
/* header {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#009c8d),
    to(#2d719e)
  );
  background-image: -webkit-linear-gradient(left, #48698c, #436489);
  background-image: -o-linear-gradient(left, #48698c, #436489);
  background-image: linear-gradient(90deg, #48698c, #436489);
  color: #fff;   
}
main {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#009c8d),
    to(#2d719e)
  );
  background-image: -webkit-linear-gradient(left, #48698c, #436489);
  background-image: -o-linear-gradient(left, #48698c, #436489);
  background-image: linear-gradient(-90deg, #48698c, #436489);
  color: #fff;  
  padding: 15px;
} */
footer {
  background-color: #14151c;
  color: #fff;
}

/* Scroll */
::-webkit-scrollbar {
  width: 10px;
  /* 1px wider than Lion. */
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 100px;
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
}

::-webkit-scrollbar-thumb:horizontal:active {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 100px;
  -webkit-border-radius: 100px;
}
/* End Scroll */
</style>
